<template>
  <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home' }"
              >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item
              :to="{ path: '/raceManagement/ibcHomeManagement' }"
              >IBC赛务管理</el-breadcrumb-item
          >
          <el-breadcrumb-item>报名信息</el-breadcrumb-item>
      </el-breadcrumb>
      <el-page-header @back="goBack"></el-page-header>
      <!-- 筛选 -->
      <el-card style="margin-top: 15px">
          <el-row class="title_row">
              <el-col>
                  <span>报名信息筛选</span>
              </el-col>
          </el-row>
          <el-form
              :model="queryForm"
              class="filter_Form"
              label-width="80px"
              ref="queryFormRef"
          >
              <el-row :gutter="50">
                  <el-col :span="6">
          <el-form-item label="届" prop="ibcSession">
            <el-select
              placeholder="请选择"
              style="width: 100%"
              v-model="queryForm.ibcSession"
              clearable
            >
              <el-option
                v-for="item in dict_ibcSession"
                :key="item.dictCode"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
                  <el-col :span="6">
                      <el-form-item label="赛季" prop="ibcSeason">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              v-model="queryForm.ibcSeason"
                              clearable
                          >
                              <el-option
                                  v-for="item in dict_ibcSeason"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="组别" prop="enrollmentLevel">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              v-model="queryForm.enrollmentLevel"
                              clearable
                          >
                              <el-option
                                  v-for="item in dict_sspcnGroup"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="手机号" prop="phone">
                          <el-input
                              v-model="queryForm.phone"
                              placeholder="请输入"
                              clearable
                          ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="姓名" prop="userName">
                          <el-input
                              v-model="queryForm.userName"
                              placeholder="请输入"
                              clearable
                          ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="国籍" prop="country">
                          <el-input
                              v-model="queryForm.country"
                              placeholder="请输入"
                              clearable
                          ></el-input>
                      </el-form-item>
                  </el-col>
              </el-row>
          </el-form>
          <el-row :gutter="20" type="flex" justify="end">
              <el-col :span="2">
                  <el-button
                      type="primary"
                      style="width: 100%"
                      @click="getDataList('search')"
                      >查询</el-button
                  >
              </el-col>
              <el-col :span="2">
                  <el-button type="primary" style="width: 100%" @click="reset"
                      >重置</el-button
                  >
              </el-col>
          </el-row>
      </el-card>
      <!-- 报名信息列表 -->
      <el-card style="margin-top: 15px">
          <el-row class="title_row" align="middle" type="flex">
              <el-col :span="9">
                  <span
                      style="
                          display: -moz-inline-box;
                          display: inline-block;
                          width: 120px;
                      "
                      >报名信息列表</span
                  >
                  <span style="color: blue; font-size: 14px">{{
                      "已报名" + totalNum + "人"
                  }}</span>
                  <span
                      style="color: blue; font-size: 14px; margin-left: 20px"
                      >{{ "缴费金额：￥" + totalPrice }}</span
                  >
              </el-col>
              <el-col :span="15" style="text-align: right">
                  <div class="div_algin_right">
                      <el-button type="primary" @click="downloadInfoList"
                          >导出信息表</el-button
                      >
                      <el-button
                          type="primary"
                          @click="editExamineeTypeClicked('0')"
                          >国内选手新增</el-button
                      >
                      <el-button
                          type="primary"
                          @click="editExamineeTypeClicked('1')"
                          >国外选手新增</el-button
                      >
                      <el-button type="primary" @click="importForeignPlayers"
                          >批量导入国外选手</el-button
                      >
                  </div>
              </el-col>
          </el-row>
          <el-table
              header-cell-class-name="tableHeaderStyle"
              :data="dataList"
              border
              stripe
          >
              <el-table-column label="#" type="index"></el-table-column>
              <el-table-column label="姓名" prop="userName"></el-table-column>
              <el-table-column
                  label="性别"
                  prop="sex"
                  :formatter="genderFormatter"
              ></el-table-column>
              <el-table-column label="省市区" prop="provence">
                  <template scope="scope" >
                      <span v-if="scope.row.province"
                          >{{ scope.row.province }}/{{
                              scope.row.city
                          }}/{{ scope.row.area }}</span
                      >
                      <span v-else>未设置</span>
                  </template>
              </el-table-column>
              <el-table-column label="学校" prop="schoolName">
                  <template scope="scope">
                      <span v-if="scope.row.schoolName">{{ scope.row.schoolName }}</span>
                      <span v-else>未设置</span>
                  </template>
              </el-table-column>
              <el-table-column
                  label="年级"
                  prop="grade"
                  :formatter="gradeFormatter"
              ></el-table-column>
              <el-table-column
                  label="组别"
                  prop="enrollmentLevel"
                  :formatter="groupFormatter"
              ></el-table-column>
              <!-- <el-table-column label="班级" prop="classes"></el-table-column> -->
              <el-table-column label="国籍" prop="country"></el-table-column>
              <el-table-column
                  label="联系方式"
                  prop="phone"
              >                <template scope="scope">
                      <span v-if="scope.row.phone">{{ scope.row.phone }}</span>
                      <span v-else>未设置</span>
                  </template></el-table-column>
              <el-table-column label="邮箱" prop="email"></el-table-column>
              <el-table-column label="选手照片" prop="oneInchPhoto">
                  <template scope="scope">
                      <el-image
                          style="width: 50px; height: 50px"
                          :src="
                              scope.row.oneInchPhoto
                                  ? scope.row.oneInchPhoto
                                  : 'https://cdn.spbcn.org/spbcnapp/userpic/yonghu_defult.png'
                          "
                          :preview-src-list="[
                              scope.row.oneInchPhoto
                                  ? scope.row.oneInchPhoto
                                  : 'https://cdn.spbcn.org/spbcnapp/userpic/yonghu_defult.png',
                          ]"
                      >
                      </el-image>
                  </template>
              </el-table-column>
              <!-- <el-table-column label="缴费状态" prop="payStatus">
                  <template slot-scope="scope">
                      <el-tag type="success" v-if="scope.row.payStatus == true"
                          >已缴费</el-tag
                      >
                      <el-tag type="warning" v-else>未缴费</el-tag>
                  </template>
              </el-table-column> -->
              <el-table-column label="缴费金额" prop="price">
                  <template slot-scope="scope">
                      <span>￥{{ scope.row.price }}</span>
                  </template>
              </el-table-column>
              <!-- <el-table-column
                  label="缴费项目"
                  prop="productName"
              ></el-table-column> -->
              <el-table-column label="报名时间" prop="createTime">
                  <template slot-scope="scope">
                      {{ scope.row.createTime | dateFormat }}
                  </template>
              </el-table-column>
              <el-table-column label="操作">
                  <template slot-scope="scope">
                      <el-button
                          type="text"
                          icon="el-icon-edit"
                          size="small"
                          @click="showEditDialog(scope.row)"
                          >修改</el-button
                      >
                  </template>
              </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryForm.pageNum"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="queryForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
          >
          </el-pagination>
      </el-card>
      <!-- 批量导入的Dialog -->
      <el-dialog
          title="批量导入报名信息"
          :visible.sync="batchImportInfoDialogVisible"
          width="30%"
      >
      <div style="color: red;">1.导入文件必须为下载模板</div>
      <div style="color: red;">2.level：0对应小初，1对应小中，2对应小高，3对应初中</div>
      <div style="color: red;">3.请勿添加空行</div>
          <el-upload
              :action="batchImportInfo.uploadUrl"
              ref="batchImportInfoRef"
              :data="{
                  ibcSeason: this.queryForm.ibcSeason,
                  ibcSession: this.queryForm.ibcSession,
                  activityType: this.$chnEngStatusCode.ibcActivityType,
              }"
              :headers="batchImportInfo.headerObj"
              :on-success="batchImportInfoSuccess"
              :on-error="batchImportInfoError"
              :on-exceed="batchImportInfoExceed"
              :limit="1"
              :auto-upload="false"
              :on-change="onChangeLoad"
          >
              <el-button slot="trigger" size="small" type="primary"
                  >选取文件</el-button
              >
              <el-button
                  style="margin-left: 10px"
                  type="primary"
                  size="small"
                  @click="downLoadbatchImportInfoStencil"
                  >下载模版</el-button
              >
              <el-button
                  style="margin-left: 10px"
                  size="small"
                  type="success"
                  @click="submitbatchImportInfo"
                  >导入报名信息</el-button
              >
          </el-upload>
      </el-dialog>
      <!-- 编辑信息的dialog -->
      <el-dialog
          title="报名信息"
          :visible.sync="editInfoDialogVisible"
          width="30%"
          @close="editInfoDialogClose"
          :destroy-on-close="true"
      >
          <el-form
              :model="editInfoForm"
              label-width="80px"
              ref="editInfoFormRef"
              :rules="editInfoRules"
              :key="timer"
          >
              <el-form-item
                  label="蜂拥小程序注册手机号"
                  :prop="examineeTypeStr"
                  label-width="180px"
                  v-if="examineeType === 0"
              >
                  <el-input
                      v-model="editInfoForm.phone"
                      placeholder="请输入"
                      :disabled="!editInfoEnable"
                      ><el-button
                          slot="append"
                          icon="el-icon-search"
                          @click="searchPhone"
                          v-if="editInfoEnable"
                      ></el-button
                  ></el-input>
              </el-form-item>
              <el-form-item label="头像" prop="oneInchPhoto">
                  <el-upload
                      class="avatar-uploader"
                      :action="uploaderAvatarInfo.uploadUrl"
                      :headers="uploaderAvatarInfo.headerObj"
                      :data="{
                          filePath: 'spbcnapp/userpic/',
                      }"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                  >
                      <img
                          v-if="editInfoForm.oneInchPhoto"
                          :src="editInfoForm.oneInchPhoto"
                          class="avatar"
                      />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
              </el-form-item>
              <el-form-item label="姓名" prop="userName">
                  <el-input
                      v-model="editInfoForm.userName"
                      placeholder="请输入"
                  ></el-input>
              </el-form-item>
              <el-form-item label="性别" prop="sex">
                  <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="editInfoForm.sex"
                  >
                      <el-option
                          v-for="item in dict_gender"
                          :key="item.dictCode"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                      ></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                  <el-input
                      v-model="editInfoForm.email"
                      placeholder="请输入"
                  ></el-input>
              </el-form-item>
              <el-form-item label="年级" prop="grade">
                  <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="editInfoForm.grade"
                      @change="gradeChangeClicked"
                  >
                      <el-option
                          v-for="item in dict_grade"
                          :key="item.dictCode"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                      ></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="组别" prop="enrollmentLevel">
                  <el-select
                      v-model="editInfoForm.enrollmentLevel"
                      placeholder="请选择"
                      style="width: 100%"
                      disabled
                  >
                      <el-option
                          v-for="item in dict_sspcnGroup"
                          :key="item.dictCode"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                      >
                      </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="学校省市区" prop="schoolArea">
                  <el-cascader
                      placeholder="请选择"
                      v-model="editInfoForm.schoolArea"
                      ref="schoolAreaRef"
                      :options="areasData"
                      style="width: 100%"
                      :props="{
                          expandTrigger: 'hover',
                          label: 'name',
                          value: 'areaId',
                          children: 'areas',
                      }"
                      @change="provincesHandleChange"
                  ></el-cascader>
              </el-form-item>
              <el-form-item label="学校名称" prop="schoolName">
                  <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="editInfoForm.schoolName"
                      @change="schoolSelectChange"
                      filterable
                      ref="schoolSelect"
                  >
                      <el-option
                          v-for="item in provincesSchoolList"
                          :key="item.id"
                          :label="item.schoolName"
                          :value="item.id"
                      ></el-option>
                  </el-select>
              </el-form-item>
              <!-- <el-form-item label="班级" prop="classes">
                  <el-input
                      v-model="editInfoForm.classes"
                      placeholder="请输入"
                      type="number"
                  ></el-input>
              </el-form-item> -->
              <el-form-item label="国籍" prop="country">
                  <el-input
                      v-model="editInfoForm.country"
                      placeholder="请输入"
                  ></el-input>
              </el-form-item>
              <el-form-item
                  label="赛季"
                  prop="ibcSeason"
                  v-if="editInfoEnable"
              >
                  <el-select
                      placeholder="请选择"
                      style="width: 100%"
                      v-model="editInfoForm.ibcSeason"
                      default-first-option
                      clearable
                  >
                      <el-option
                          v-for="item in dict_ibcSeason"
                          :key="item.dictCode"
                          :label="item.dictLabel"
                          :value="item.dictValue"
                      ></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item
                  label="是否缴费"
                  prop="isPrice"
                  v-if="editInfoEnable"
              >
                  <el-radio-group
                      v-model="editInfoForm.isPrice"
                      :disabled="!editInfoEnable"
                  >
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                  </el-radio-group>
              </el-form-item>
              <el-form-item
                  label="缴费金额"
                  prop="price"
                  v-if="editInfoForm.isPrice === 1 && editInfoEnable"
              >
                  <el-input-number
                      v-model="editInfoForm.price"
                      placeholder="请输入"
                      :disabled="!editInfoEnable"
                  ></el-input-number>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="editInfoDialogVisible = false"
                  >取 消</el-button
              >
              <el-button type="primary" @click="editInfo">保 存</el-button>
          </span>
      </el-dialog>
  </div>
</template>

<script>
import {
  getAllAreaList, queryIbcTbEnrollmentPage, getIbcPurchaseNumPrice, getSchoolListWithCity,
  queryIbcUserInfoById, updateIbcUserInfo, querySysUserByPhone, queryIbcSeason
} from '@/http/api'
export default {
  data () {
    return {
      areasData: [],
      provincesSchoolList: [],
      dict_grade: this.$userInfo.getGradeData(),
      timer: new Date().getTime(),
      queryForm: {
        phone: '',
        activityType: this.$chnEngStatusCode.ibcActivityType,
        userName: '',
        group: '',
        country: '',
        ibcSeason: this.$route.query.ibcSeason,
        ibcSession: this.$ibcManager.ibcSession,
        pageNum: 1,
        pageSize: 10
      },
      totalNum: 0, // 报名总人数
      totalPrice: 0, // 报名总费用
      flagType: '', // 赛区非赛区标识
      isUploadFile: false, // 是否已选择上传文件
      examineeType: 0,
      total: 0,
      dict_gender: this.$userInfo.getDataList(this, 'e_sex').then(value => { this.dict_gender = value }),
      dict_ibcSeason: this.$userInfo.getDataList(this, 'ibc_season').then(value => { this.dict_ibcSeason = value }),
      dict_sspcnGroup: this.$userInfo.dict_group(),
      dict_ibcSession: this.$userInfo.getDataList(this, 'ibc_session').then(value => { this.dict_ibcSession = value }),
      dataList: [],
      editSelectTypeInfoDialogVisible: false,
      editInfoDialogVisible: false,
      batchImportInfoDialogVisible: false,
      batchImportInfo: {
        uploadUrl: this.$http.defaults.baseURL + 'ibc/importIbcTbEnrollmentList',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      },
      schoolCityList: [],
      editInfoForm: {
        phone: '',
        oneInchPhoto: '',
        userName: '',
        gender: '',
        email: '',
        schoolArea: [],
        schoolId: '',
        schoolName: '',
        enrollmentLevel: null,
        // classes: '',
        grade: '',
        country: '',
        ibcSeason: this.$route.query.ibcSeason,
        ibcSession: this.$ibcManager.ibcSession,
        isPrice: '',
        price: '',
        isAbroad: 0
      },
      editInfoRules: {
        phone: [{ required: true, message: '请输入', trigger: 'blur' }],
        country: [{ required: true, message: '请输入', trigger: 'blur' }],
        userName: [{ required: true, message: '请输入', trigger: 'blur' }],
        gender: [{ required: true, message: '请选择', trigger: 'change' }],
        email: [{ required: true, message: '请输入', trigger: 'blur' }],
        grade: [{ required: true, message: '请选择', trigger: 'change' }],
        isPrice: [{ required: true, message: '请选择', trigger: 'change' }],
        ibcSeason: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      editInfoEnable: true, //  缴费信息项是否可编辑
      uploaderAvatarInfo: {
        uploadUrl: this.$http.defaults.baseURL + 'upload/uploadFileAliOss',
        headerObj: {
          Authorization: localStorage.getItem('Authorization')
        }
      }
    }
  },
  created () {
    this.getAllAreaList()
    // this.getDataList()
  },
  methods: {
    getQueryIbcSeason () {
      queryIbcSeason().then((res) => {
        const newObj = Object.entries(res.data).reduce((obj, [key, value]) => {
          obj[key] = value.toString()
          return obj
        }, {})
        console.log('newObj', newObj)
        if (newObj.ibcSession) {
          this.queryForm.ibcSession = newObj.ibcSession
        }
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
        this.getQueryIbcSeason()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // 获取列表信息
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      queryIbcTbEnrollmentPage(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
        this.getIbcPurchaseNumPrice()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 获取金额和总人数
    getIbcPurchaseNumPrice () {
      getIbcPurchaseNumPrice(this.queryForm).then((res) => {
        if (res.data.totalNum) {
          this.totalPrice = res.data.totalPrice
          this.totalNum = res.data.totalNum
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    // ibc下载用户报名表
    async downloadInfoList () {
      var downLoadUrl = this.$http.defaults.baseURL + 'ibc/excelIbcTbEnrollmentList'
      var formData = JSON.parse(JSON.stringify(this.queryForm))
      formData.pageSize = 1000
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', formData)
    },
    // 获取用户资料详情
    showEditDialog (data) {
      queryIbcUserInfoById({ id: data.id }).then((res) => {
        this.editInfoForm = res.data
        if (!this.editInfoForm.phone) {
          this.examineeType = 1
        } else {
          this.examineeType = 0
        }
        if (this.editInfoForm.areaCode && this.editInfoForm.areaCode !== null) {
          this.editInfoForm.schoolArea = [this.editInfoForm.provinceCode, this.editInfoForm.cityCode, this.editInfoForm.areaCode]
        }
        this.editInfoEnable = false
        this.timer = new Date().getTime()
        this.editInfoDialogVisible = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    batchImport () {
      this.$message.error('接口未实现')
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    genderFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_gender) {
        if (Object.hasOwnProperty.call(this.dict_gender, key)) {
          const element = this.dict_gender[key]
          if (element.dictValue === data.sex + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    gradeFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_grade) {
        if (Object.hasOwnProperty.call(this.dict_grade, key)) {
          const element = this.dict_grade[key]
          if (element.dictValue === data.grade) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_sspcnGroup) {
        if (Object.hasOwnProperty.call(this.dict_sspcnGroup, key)) {
          const element = this.dict_sspcnGroup[key]
          if (element.dictValue === Number(data.enrollmentLevel)) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    batchImportInfoSuccess (res) {
      this.$refs.batchImportInfoRef.clearFiles()// 清除文件
      this.isUploadFile = false
      if (res.code !== 200) {
        return this.$alert('请修改后再上传，失败原因:' + res.msg, '上传失败', {
          confirmButtonText: '确定',
          type: 'warning',
          dangerouslyUseHTMLString: true
        })
      }
      this.$message.success('批量上传报名信息成功')
      this.getDataList()
      this.$refs.batchImportInfoRef.clearFiles()// 清除文件
      this.batchImportInfoDialogVisible = false
    },
    batchImportInfoError (error, file, fileList) {
      console.log('batchImportInfoError', error, file, fileList)
      this.$message.error(error)
    },
    batchImportInfoExceed (files, fileList) {
      console.log('fileList', files, fileList)
      this.$set(fileList[0], 'raw', files[0])
      this.$set(fileList[0], 'name', files[0].name)
      this.$refs.batchImportInfoRef.clearFiles()// 清除文件
      this.$refs.batchImportInfoRef.handleStart(files[0])// 选择文件后的赋值方法
    },
    submitbatchImportInfo () {
      console.log('this.isUploadFile', this.isUploadFile)
      if (this.isUploadFile === true) {
        this.$refs.batchImportInfoRef.submit()
      } else {
        this.$message.error('请先选择要上传的文件')
      }
    },
    // 监听上传文件弹窗关闭事件
    batchImportInfoDialogClose () {
      this.$refs.batchImportInfoRef.clearFiles()// 清除文件
      this.isUploadFile = false
    },
    // ================   批量上传报名信息的Dialog end  =================
    downLoadbatchImportInfoStencil () {
      var downLoadUrl = this.$http.defaults.baseURL + 'ibc/downloadIbcAbroadTemplate'
      var formData = { ibcSession: this.queryForm.ibcSession, ibcSeason: this.queryForm.ibcSeason }
      this.$downLoadFile.downLoadFileWithFile(this, downLoadUrl, formData)
    },
    // 编辑弹窗关闭事件
    editInfoDialogClose () {
      this.editInfoForm = {
        phone: '',
        oneInchPhoto: '',
        userName: '',
        gender: '',
        email: '',
        schoolArea: [],
        schoolId: '',
        schoolName: '',
        // classes: '',
        grade: '',
        country: '',
        ibcSeason: this.$route.query.ibcSeason,
        ibcSession: this.$ibcManager.ibcSession,
        isPrice: '',
        price: '',
        isAbroad: 0
      }
      this.$refs.editInfoFormRef.resetFields()
      this.editInfoEnable = true
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      console.log('value', value)
      if (!value) return
      this.provincesSchoolList = []
      this.editInfoForm.schoolName = ''
      this.editInfoForm.schoolId = ''
      this.editInfoForm.provinceCode = value[0]
      this.editInfoForm.cityCode = value[1]
      this.editInfoForm.areaCode = value[2]
      console.log('editInfoForm', this.editInfoForm)
      this.getSchoolListData(value)
    },
    // 获取学校List
    getSchoolListData (schoolArea) {
      getSchoolListWithCity({ provinceCode: schoolArea[0], cityCode: schoolArea[1], areaCode: schoolArea[2] }).then((res) => {
        this.provincesSchoolList = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 学校选择赋值schoolId
    schoolSelectChange (schoolid) {
      this.editInfoForm.schoolId = schoolid
    },
    // 保存添加的选手信息
    editInfo () {
      this.editInfoForm.isAbroad = this.examineeType
      if (this.editInfoForm.schoolArea && this.editInfoForm.schoolArea.length === 3) {
        this.editInfoForm.provinceCode = this.editInfoForm.schoolArea[0]
        this.editInfoForm.cityCode = this.editInfoForm.schoolArea[1]
        this.editInfoForm.areaCode = this.editInfoForm.schoolArea[2]
      }
      this.editInfoForm.schoolName = this.$refs.schoolSelect.selectedLabel
      var dataForm = {}
      this.$refs.editInfoFormRef.validate(async valid => {
        if (!valid) return
        if (this.editInfoEnable) {
          var editInfoFormData = JSON.parse(JSON.stringify(this.editInfoForm))
          if (editInfoFormData.price !== null && editInfoFormData.price !== '') {
            editInfoFormData.price = this.editInfoForm.price
          }
          if (editInfoFormData.id) {
            delete editInfoFormData.id
          }
          dataForm = editInfoFormData
        } else {
          dataForm = this.editInfoForm
        }
        dataForm.activityType = this.$chnEngStatusCode.ibcActivityType
        dataForm.ibcSeason = this.queryForm.ibcSeason
        dataForm.ibcSession = this.queryForm.ibcSession
        updateIbcUserInfo(dataForm).then((res) => {
          this.$message.success('保存成功')
          this.editInfoDialogVisible = false
          this.getDataList()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    // 搜索手机号显示用户信息
    async searchPhone () {
      if (this.editInfoForm.phone === '') {
        return this.$message.warning('请先填写手机号')
      }
      querySysUserByPhone({ phone: this.editInfoForm.phone }).then((res) => {
        this.editInfoForm = res.data
        if (this.editInfoForm.areaCode) {
          this.editInfoForm.schoolArea = [this.editInfoForm.provinceCode, this.editInfoForm.cityCode, this.editInfoForm.areaCode]
        }
        if (this.editInfoForm.grade) {
          this.editInfoForm.enrollmentLevel = Number(this.$userInfo.getUserGroupWithGrade(this.editInfoForm.grade).groupId)
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    handleAvatarSuccess (res, file) {
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.editInfoForm.oneInchPhoto = res.data
    },
    beforeAvatarUpload (file) {
      const isJPGPNG = (file.type === 'image/jpeg' || file.type === 'image/png')
      const isLt2M = file.size / 1024 / 200
      if (!isJPGPNG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 200KB!')
      }
      return isJPGPNG && isLt2M
    },
    // 国内、外籍选手新增点击事件
    editExamineeTypeClicked (type) {
      this.examineeType = Number(type)
      this.schoolCityList = []
      this.timer = new Date().getTime()
      this.editInfoDialogVisible = true
    },
    // 批量导入国外选手
    importForeignPlayers () {
      this.batchImportInfoDialogVisible = true
    },
    // 选择文件修改状态
    onChangeLoad (files, fileList) {
      if (fileList.length > 0) {
        this.isUploadFile = true
      }
    },
    gradeChangeClicked () {
    // console.log('editInfoForm', this.$userInfo.getUserGroupWithGrade(this.editInfoForm.grade))
      this.editInfoForm.enrollmentLevel = Number(this.$userInfo.getUserGroupWithGrade(this.editInfoForm.grade).groupId)
    }
  },
  computed: {
    examineeTypeStr () {
      switch (this.examineeType) {
        case 0: // 国内选手
          return 'phone'
        case 1: // 国外选手
          return ''
      }
      return ''
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.edit-selecttype-backView {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.examinee-button-type {
  width: 200px;
  margin-bottom: 15px;
}
</style>
